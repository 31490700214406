import { CaretRight, RubleSign } from 'src/shared/icons';
import { Col, List, Row, Typography } from 'antd';
import { ListComponent } from 'src/shared/components/List/List';
import { Specialty, WidgetServiceRequestType } from 'src/shared/types';
import { goToPage } from 'src/shared/redux/routing/routingSlice';
import { setServiceRequestType, setSpecialty } from 'src/shared/redux/appointment/appointmentSlice';
import { useAppDispatch } from 'src/hooks';
import React from 'react';
import styles from './styles.module.scss';

interface SpecialtiesListProps {
  specialties: Specialty[];
  serviceType: WidgetServiceRequestType;
}

export const SpecialtiesList = ({ specialties, serviceType }: SpecialtiesListProps) => {
  const dispatch = useAppDispatch();

  const handleMenuClick = (item: Specialty) => {
    dispatch(
      setSpecialty({
        idFerSpeciality: item.idFerSpeciality,
        milaSpecialityName: item.milaSpecialityName,
        minServiceCost: item.minServiceCost,
        maxServeAge: item.maxServeAge,
        minServeAge: item.minServeAge,
        doctorCount: item.doctorCount,
      }),
    );
    dispatch(setServiceRequestType(serviceType));
    dispatch(goToPage('doctors'));
  };

  return (
    <ListComponent
      containerClass={styles.ListContainer}
      data={specialties}
      renderFunc={(item) => (
        <List.Item onClick={() => handleMenuClick(item)} className={styles.ListItem}>
          <Row className={styles.SpecialtyRow}>
            <Col className={styles.LeftCol}>
              <Typography className={styles.SpecialityName}>{item.milaSpecialityName}</Typography>
              <Row className={styles.InnerRow}>
                <Typography className={styles.DoctorAmount}>{item.doctorCount}</Typography>
              </Row>
            </Col>
            <Col className={styles.RightCol}>
              <Typography className={styles.Price}>
                от {item.minServiceCost}
                <RubleSign className={styles.Ruble} />
              </Typography>

              <CaretRight className={'IconFill'} />
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};
