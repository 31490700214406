import {
  AdditionalEmailData,
  AppointmentData,
  CalendarData,
  ClinicInfo,
  Documents,
  Patient,
  PatientData,
  PhoneCallData,
  SearchArgsType,
  SearchData,
  WhiteLabelInfo,
  WidgetInfo,
  WidgetSpecialitiesArgsType,
} from 'src/shared/types/widget.types';
import {
  Doctor,
  DoctorsArgs, ScheduleDoctorWithFerAndDoctorIds, DoctorsListArgs,
  Education,
  ScheduleArgs, ScheduleDoctor,
  ScheduleUI,
  Service,
  ServicesArgs,
  Specialty
} from 'src/shared/types';
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { tokenProviderService } from 'src/services/login/TokenProviderService';
import { v4 as uuidv4 } from 'uuid';

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const networkCode = params.networkId ?? '';
const businessCode = params.businessId ?? '';

if (!networkCode && !businessCode) {
  throw new Error('Wrong widget setup!');
}

var slotsLocker = window.sessionStorage.getItem('X-Mila-UserSession');

if (!slotsLocker) {
  slotsLocker = uuidv4();
  window.sessionStorage.setItem('X-Mila-UserSession', slotsLocker);
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: window.__RUNTIME_CONFIG__.REACT_APP_API,
    headers: {
      'X-Mila-UserSession': slotsLocker,
    },
  }),
  endpoints: (builder) => ({
    getWidgetInfo: builder.query<WidgetInfo, string>({
      query: () => `/info?NetworkCode=${networkCode}&BusinessCode=${businessCode}`,
    }),
    getWhiteLabelInfo: builder.query<WhiteLabelInfo, string>({
      query: () => `/info/whiteLabel?NetworkCode=${networkCode}&BusinessCode=${businessCode}`,
    }),
    getClinicInfo: builder.query<ClinicInfo, string>({
      query: (idMedOrganization) =>
        `/info/${idMedOrganization}?NetworkCode=${networkCode}&BusinessCode=${businessCode}`,
    }),
    getSpecialties: builder.query<Specialty[], WidgetSpecialitiesArgsType>({
      query: (arg) => ({
        url: `/specialities`,
        params: {
          NetworkCode: networkCode,
          BusinessCode: businessCode,
          ServiceRequestType: arg.serviceRequestType,
          ServeAge: arg.serveAge,
          branchId: arg.branchId
        },
      }),
    }),
    getDoctors: builder.query<Doctor[], DoctorsArgs>({
      query: (arg) => ({
        url: `/specialities/${arg.idFerSpeciality}/doctors`,
        params: {
          DateFrom: new Date(arg.dateFrom).toISOString(),
          DateTo: new Date(arg.dateTo).toISOString(),
          NetworkCode: networkCode,
          BusinessCode: businessCode,
          ServiceRequestType: arg.serviceRequestType,
        },
      }),
    }),
    getDoctorsList: builder.query<Doctor[], DoctorsListArgs>({
      query: (arg) => ({
        url: `/doctors`,
        params: {
          NetworkCode: networkCode,
          BusinessCode: businessCode,
          Region: arg.region,
        },
      }),
    }),
    getRegions: builder.query<string[], any>({
      query: () => ({
        url: `/regions`,
        params: {
          NetworkCode: networkCode,
          BusinessCode: businessCode
        },
      }),
    }),
    getDoctor: builder.query<Doctor, string>({
      query: (docId) => `/doctors/${docId}/info?NetworkCode=${networkCode}&BusinessCode=${businessCode}`,
    }),
    getSchedule: builder.query<ScheduleDoctor[], ScheduleArgs>({
      query: (arg) => ({
        url: `/specialities/${arg.idFerSpeciality}/doctors/${arg.idDoctor}/schedule`,
        params: {
          DateFrom: new Date(arg.dateFrom).toISOString(),
          DateTo: new Date(arg.dateTo).toISOString(),
          NetworkCode: networkCode,
          BusinessCode: businessCode,
          ServiceRequestType: arg.serviceRequestType,
        },
      }),
    }),
    getDoctorsSchedules: builder.query<ScheduleDoctorWithFerAndDoctorIds[], any>({
      queryFn: async ({ids, dateFrom, dateTo, serviceRequestType}: 
                    {ids: {idFerSpeciality: any, idDoctor: any}[], dateFrom: any, dateTo: any, serviceRequestType?: any}) => {
        const query = new URLSearchParams({
          DateFrom: new Date(dateFrom).toISOString(),
          DateTo: new Date(dateTo).toISOString(),
          NetworkCode: networkCode,
          BusinessCode: businessCode,
        });
        if (serviceRequestType) {
          query.set('ServiceRequestType', serviceRequestType);
        }
        const promises = ids.map(({idFerSpeciality, idDoctor}) => {
          return fetch(`${window.__RUNTIME_CONFIG__.REACT_APP_API}/specialities/${idFerSpeciality}/doctors/${idDoctor}/schedule?` + query.toString(), {headers: {'X-Mila-UserSession': slotsLocker} as any})
              .then(res => res.json())
              .then((result: ScheduleDoctor[]) => ({
                idFerSpeciality,
                idDoctor,
                schedule: result
              }));
        });
        const result = await Promise.all(promises).then((results) => {
          return {doctorsSchedules: results};
        });
        return result.doctorsSchedules
            ? { data: result.doctorsSchedules as ScheduleDoctorWithFerAndDoctorIds[] }
            : { error: {error: ''} as FetchBaseQueryError };
      },
    }),
    getServices: builder.query<Service[], ServicesArgs>({
      query: (arg) => `/doctors/${arg.idMilaDoctor}/services?NetworkCode=${networkCode}&BusinessCode=${businessCode}`,
    }),
    getEducation: builder.query<Education[], string>({
      query: (arg) => `/doctors/${arg}/education?NetworkCode=${networkCode}&BusinessCode=${businessCode}`,
    }),
    getPatients: builder.query<Patient[], string>({
      query: () => ({
        url: `/user/patients`,
        headers: { Authorization: `Bearer ${tokenProviderService.getToken()}` },
      }),
    }),
    updatePatient: builder.mutation<Patient, PatientData>({
      query: ({ idPatient, ...patch }) => ({
        url: `/user/patients/${idPatient}`,
        headers: { Authorization: `Bearer ${tokenProviderService.getToken()}` },
        method: 'PUT',
        body: patch,
      }),
    }),
    createPatient: builder.mutation<Patient, PatientData>({
      // eslint-disable-next-line unused-imports/no-unused-vars
      query: ({ idPatient, ...patch }) => ({
        url: `/user/patients`,
        headers: { Authorization: `Bearer ${tokenProviderService.getToken()}` },
        method: 'POST',
        body: patch,
      }),
    }),

    confirmAppointment: builder.mutation<string, AppointmentData>({
      query: ({ idPatient, ...patch }) => ({
        url: `/user/patients/${idPatient}/appointments`,
        headers: { Authorization: `Bearer ${tokenProviderService.getToken()}` },
        method: 'POST',
        body: { ...patch, networkCode, businessCode },
      }),
    }),

    signUpInWaitingList: builder.mutation<string, AppointmentData>({
      query: ({ idPatient, ...patch }) => ({
        url: `/user/patients/${idPatient}/waiting-list`,
        headers: { Authorization: `Bearer ${tokenProviderService.getToken()}` },
        method: 'POST',
        body: { ...patch, networkCode, businessCode },
      }),
    }),

    requestPhoneCall: builder.mutation<string, PhoneCallData>({
      query: (args) => ({
        url: `/user/callmeback?NetworkCode=${networkCode}&BusinessCode=${businessCode}`,
        method: 'POST',
        body: { ...args },
      }),
    }),

    getCalendarEvent: builder.query<string, CalendarData>({
      query: (args) => ({
        url: `/user/patients/${args.idPatient}/appointments/${args.appointmentId}/ics?NetworkCode=${networkCode}&BusinessCode=${businessCode}`,
        method: 'GET',
        headers: { Authorization: `Bearer ${tokenProviderService.getToken()}` },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseHandler: async (response: any) =>
          window.location.assign(window.URL.createObjectURL(await response.blob())),
        cache: 'no-cache',
      }),
    }),

    sendToAdditionalEmail: builder.mutation<string, AdditionalEmailData>({
      query: ({ idPatient, appointmentId, email }) => ({
        url: `/user/patients/${idPatient}/appointments/${appointmentId}/additional-email`,
        headers: { Authorization: `Bearer ${tokenProviderService.getToken()}` },
        method: 'POST',
        body: { email, networkCode, businessCode },
      }),
    }),

    search: builder.query<SearchData, SearchArgsType>({
      query: (args) => ({
        url: '/search',
        params: {
          Data: args.data,
          NetworkCode: networkCode,
          BusinessCode: businessCode,
          ServeAge: args.serveAge,
          Region: args.region,
          BranchId: args.branchId
        },
      }),
    }),

    getDocuments: builder.query<Documents, string>({
      query: () => `/info/documents?NetworkCode=${networkCode}&BusinessCode=${businessCode}`,
    }),
  }),
});

export const {
  useGetWidgetInfoQuery,
  useGetWhiteLabelInfoQuery,
  useGetSpecialtiesQuery,
  useGetDoctorsQuery,
  useGetDoctorsListQuery,
  useGetRegionsQuery,
  useGetDoctorsSchedulesQuery,
  useGetServicesQuery,
  useGetEducationQuery,
  useGetDoctorQuery,
  useGetScheduleQuery,
  useGetClinicInfoQuery,
  useLazyGetPatientsQuery,
  useUpdatePatientMutation,
  useCreatePatientMutation,
  useConfirmAppointmentMutation,
  useSignUpInWaitingListMutation,
  useRequestPhoneCallMutation,
  useLazyGetCalendarEventQuery,
  useSendToAdditionalEmailMutation,
  useLazySearchQuery,
  useGetDocumentsQuery,
} = apiSlice;
